const isDom = () => typeof document !== "undefined";
function getPlatform() {
  const agent = navigator.userAgentData;
  return agent?.platform ?? navigator.platform;
}
const pt = (v) => isDom() && v.test(getPlatform());
const ua = (v) => isDom() && v.test(navigator.userAgent);
const vn = (v) => isDom() && v.test(navigator.vendor);
const isTouchDevice = () => isDom() && !!navigator.maxTouchPoints;
const isMac = () => pt(/^Mac/) && !isTouchDevice();
const isIPhone = () => pt(/^iPhone/);
const isSafari = () => isApple() && vn(/apple/i);
const isFirefox = () => ua(/firefox\//i);
const isApple = () => pt(/mac|iphone|ipad|ipod/i);
const isIos = () => isApple() && !isMac();

export { getPlatform, isApple, isDom, isFirefox, isIPhone, isIos, isMac, isSafari, isTouchDevice };
