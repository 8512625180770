import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  faChevronDown,
  faChevronUp,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Box, IconButton } from "@chakra-ui/react";
import { MinusIcon, AddIcon } from "@chakra-ui/icons";

export const GUIWrapper = ({ show, children, onToggle, ...rest }) => (
  <GUIWrapperRoot className={`${show ? "show" : "hide"}`} {...rest}>
    <GUITitle onClick={onToggle} show={show}>
      {show ? "Close Controls" : "Open Controls"}
    </GUITitle>
    <GUIBody>{children}</GUIBody>
  </GUIWrapperRoot>
);

const GUIWrapperRoot = styled(Box)`
  --GUI-primary: var(--colors-modes-dark-neutral-0);
  --GUI-secondary: var(--colors-modes-dark-neutral-1);
  --GUI-accent: var(--colors-primary);
  --GUI-text: white;
  position: fixed;
  right: max(calc((100vw - var(--maxScreenWidth)) / 2 + var(--space-4)), 20px);
  bottom: 0;
  background: var(--GUI-secondary);
  transition: transform 0.2s ease-in-out;
  box-shadow: var(--shadows-high);
  color: var(--GUI-text);

  &.hide {
    transform: translateY(100%);
    opacity: 0.8;
    box-shadow: var(--shadows-low);
  }
`;

const GUITitle = ({ show, onClick, children }) => (
  <TitleRoot onClick={onClick}>
    <FontAwesomeIcon icon={show ? faChevronDown : faChevronUp} />
    {children}
  </TitleRoot>
);

const TitleRoot = styled.div`
  background: var(--GUI-primary);
  color: var(--GUI-text);
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  position: absolute;
  top: -30px;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  gap: 10px;
`;

export const GUIFootnote = styled.i`
  margin-top: 10px;
  display: block;
`;

const GUIBody = styled.div`
  padding: 20px;
  gap: 5px;
  display: flex;
  flex-direction: column;
`;

export const GUIRow = ({ title, children }) => (
  <GUIRowRoot>
    <div>{title}</div>
    <GUIControlValue>{children}</GUIControlValue>
  </GUIRowRoot>
);

const GUIRowRoot = styled.div`
  display: flex;
  justify-content: space-between;
  &:not(:last-of-type) {
    padding-bottom: 5px;
    border-bottom: 1px solid var(--GUI-accent);
  }
`;

const GUIControlValue = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 250px;
  user-select: none;
`;

const GUIValue = styled.div`
  padding: 3px 5px;
  margin: 0 7px;
  background: var(--GUI-primary);
  border-radius: 35px;
  -webkit-appearance: none;
  outline: none;
  width: 60px;
  text-align: center;
`;

const GUISelectRoot = styled.select`
  background-color: var(--GUI-primary);
  box-sizing: content-box;
  color: white;
  border-radius: 10px;
  padding-left: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
`;

export const GUISelect = ({
  options,
  ...restProps
}: {
  options: Record<string, string>[];
}) => {
  // console.log(options);
  return (
    <GUISelectRoot {...restProps}>
      {options.map(({ key, label }) => (
        <option key={key} value={key}>
          {label}
        </option>
      ))}
    </GUISelectRoot>
  );
};

export const GUISwitch = ({ onChange }) => {
  const [toggle, setToggle] = useState(false);

  return (
    <GUISwitchRoot>
      <input
        type="checkbox"
        onChange={() => {
          setToggle((value) => {
            onChange(!value);
            return !value;
          });
        }}
        checked={toggle}
      />
      <span></span>
    </GUISwitchRoot>
  );
};

const GUISwitchRoot = styled.label`
  position: relative;
  display: inline-block;
  width: 50px;
  height: 20px;
  margin-left: 10px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + span {
      background-color: var(--GUI-accent);
    }

    &:focus + span {
      box-shadow: 0 0 1px var(--GUI-secondary);
    }

    &:checked + span:before {
      transform: translateX(29px);
    }
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--GUI-primary);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 10px;
    &:before {
      position: absolute;
      content: "";
      height: 12px;
      width: 12px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 34px;
    }
  }
`;

export const GUIStepper = ({
  lowerbound,
  upperbound,
  currentValue,
  step = 1,
  onChange,
}) => {
  return (
    <>
      <IconButton
        aria-label="decrement value"
        size="sm"
        isRound={true}
        isDisabled={currentValue <= lowerbound}
        onClick={() => {
          onChange({ step: step * -1 });
        }}
        icon={<MinusIcon />}
      />
      <GUIValue>{currentValue}</GUIValue>
      <IconButton
        aria-label="increment value"
        size="sm"
        isDisabled={currentValue >= upperbound}
        isRound={true}
        onClick={() => {
          onChange({ step });
        }}
        icon={<AddIcon />}
      />
    </>
  );
};
