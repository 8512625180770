import { useEffect, useState } from "react";
import firebase from "@services/firestore";
import { isCreativeOrAdBuyID } from "../util/idTypeChecker.js";

/* 
    if the buildId is a ad line id or creative id, we'll need to find the buildId to load
    here we'll need to query for a scrub session with the ad/creative id and access the 
    parent collection for the buildId
*/
export default function useStudioBuildID(id) {
  const [buildID, setBuildID] = useState(id);
  useEffect(() => {
    if (isCreativeOrAdBuyID(id)) {
      const dataRef = firebase
        .firestore()
        .collectionGroup("scrubSessions")
        .where("creativeID", "==", id)
        .limit(1);
      dataRef.get().then((querySnapshot) => {
        if (querySnapshot.docs.length > 0) {
          setBuildID(querySnapshot.docs[0].ref.parent.parent.id);
        } else {
          throw "No data.";
        }
      });
    } else {
      setBuildID(id);
    }
  }, [id]);
  return buildID;
}
