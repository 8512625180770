import { formatSwitch } from "@utils/creativeUtil";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Text } from "@ui-lib";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import mobileFrame from "../../assets/images/iphonexcrop.png";
import { applyHeaderColorToIframe } from "../../util/applyHeaderColorToIframe";
import { Box, Flex } from "@chakra-ui/react";

const IFRAME_DEFAULT_SCALE_FACTOR = 0.8;

const PreviewerIFrame = forwardRef((props, ref) => {
  const [scale, setScale] = useState(0.8);

  const demoBuildIds = ["nikaya", "nayoyo"]; //TODO: move

  const {
    preventUserScroll = false,
    creativeFormat,
    showWrapper = true,
    heading = null,
  } = props;

  const wrapperRef = useRef(); // for scaling
  const mainFrameRef = useRef();
  const seamlessFrameRef = useRef();

  const [animatedIn, setAnimatedIn] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      mainFrame: mainFrameRef.current,
      animateIn: () => {
        if (!animatedIn && seamlessFrameRef.current) {
          setAnimatedIn(() => {
            setTimeout(() => {
              seamlessFrameRef.current.contentWindow.scrollTo({
                top: 1300,
                behavior: "smooth",
              });
            }, 1000);

            return true;
          });
        }
      },
    }),
    [ref]
  );

  const handleResize = () => {
    // get the iframes parent height
    const iframe = wrapperRef.current;
    const parentHeight = iframe?.parentElement?.clientHeight;
    if (!parentHeight) return;

    // set the default scale factor
    let scaleFactor = props.scale ?? IFRAME_DEFAULT_SCALE_FACTOR;

    const containerWidth =
      document.getElementById("creative-viewer-container")?.clientWidth ||
      window.innerWidth;

    // if the window is tall and thin, limit the scale of the iframe
    // to prevent it from being cropped
    const containerAspectRatio = containerWidth / window.innerHeight;
    if (containerAspectRatio < 1) {
      scaleFactor = Math.min(0.4, containerAspectRatio);
      console.log("scaleFactor", scaleFactor);
    }

    // if it's a presentation mode, make the iframe scale to full
    if (isPresentationMode) scaleFactor = 0.99; // 100% scale causes some weird issues

    setScale((parentHeight / 745) * scaleFactor);
  };

  const onIframeLoad = (e) => {
    // change bg of header to match chart key color
    if (props.headerColor) {
      // Recolors the menubar of the ad preview to match the header colour provided
      // used in AB tests so the user can visually link a preview to a line chart colour
      applyHeaderColorToIframe(mainFrameRef, props.headerColor);
    }

    if (props?.onAdProgress) {
      seamlessFrameRef.current = e.target;
      const seamlessFrame = seamlessFrameRef.current.contentDocument
        .getElementById("middle-ad")
        .getElementsByTagName("iframe")[0];

      const format = formatSwitch(
        seamlessFrame.contentWindow.xyzContext.dimension.creative.format
      );

      const getProgressRef = (format, mode) => {
        if (seamlessFrame.contentWindow[format]) {
          return seamlessFrame.contentWindow[format][mode];
        } else {
          return seamlessFrame.contentWindow.format[mode];
        }
      };

      seamlessFrameRef.current.contentWindow.addEventListener("scroll", () => {
        props.onAdProgress(
          getProgressRef(
            format,
            props.scrollMode === "timeline" ? "timeline" : "progress"
          )
        );
      });
    }

    if (props?.onLoadHandler) {
      props.onLoadHandler(e);
    }
    handleResize();
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const queryParams = new URLSearchParams(window.location.search);
  const isPresentationMode = queryParams.get("layoutMode") === "presentation";

  return (
    <IframeWrapper
      ref={wrapperRef}
      className="iframe-wrapper"
      alignItems={isPresentationMode ? "flex-start" : "center"}
      h={isPresentationMode ? "100vh" : "auto"}
    >
      <DeviceFrame transform={`scale(${scale})`}>
        {heading && (
          <div style={{ position: "absolute", top: "0px", width: "100%" }}>
            {heading}
          </div>
        )}

        <Box
          as="iframe"
          {...mobileIframeStyle}
          title="iframe"
          ref={mainFrameRef}
          pointerEvents={preventUserScroll ? "none" : "auto"}
          data-xyz="phone-wrapper"
          srcDoc={props.srcDoc}
          onLoad={(e) => {
            onIframeLoad(e);
          }}
        />
      </DeviceFrame>

      {!demoBuildIds.includes(props.creativeID) && (
        <MetaContainer display={isPresentationMode ? "none" : "block"}>
          {props.scriptVersion && (
            <Text type="body02"> AdSDK Version: {props.scriptVersion}</Text>
          )}
          <Text type="body02">
            {/* { props.attentionTime && (
              <> <br /> AT: {props.attentionTime} &nbsp;</>
             )}
             { props.sessionSize && props.isAB && (
              <> | &nbsp; Session size: {props.sessionSize} </>
            )} */}
          </Text>
        </MetaContainer>
      )}
    </IframeWrapper>
  );
});

PreviewerIFrame.propTypes = {
  srcDoc: PropTypes.string,
  creativeFormat: PropTypes.string,
  onLoadHandler: PropTypes.func,
  scriptVersion: PropTypes.string,
  resetTransform: PropTypes.bool,
  scrollMode: PropTypes.string,
  onAdProgress: PropTypes.func,
  creativeID: PropTypes.string,
  preventUserScroll: PropTypes.bool,
  headerColor: PropTypes.string,
};

const IframeWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const mobileIframeStyle = {
  w: "100%",
  h: "auto",
  margin: "76px 26px 20px 24px",
  border: "none",
  borderRadius: "0px 0px 36px 36px",
  boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset",
};

const DeviceFrame = ({ children, ...rest }) => {
  return (
    <Flex
      background={`url(${mobileFrame})`}
      width="375px"
      height="745px"
      transformOrigin="top"
      alignItems="stretch"
      transition="transform 0.15s ease"
      justifyContent="center"
      backgroundColor="white"
      backgroundSize="contain"
      backgroundRepeat="no-repeat"
      border="none"
      borderRadius="10px 10px 60px 60px"
      {...rest}
    >
      {children}
    </Flex>
  );
};

const MetaContainer = styled(Box)`
  position: absolute;
  bottom: var(--space-4);
  // background: white;
  padding: var(--space-1) var(--space-2);
  border-radius: 10px;
  z-index: 10;
  opacity: 0.7;
  text-align: center;
  position: fixed;
  & * {
    margin: 0;
    line-height: 1.25;
  }
  &:hover {
    opacity: 1;
  }
`;

export default PreviewerIFrame;
