import { Box, HStack, IconButton, VStack } from "@chakra-ui/react";
import { EditableDescription } from "@components/UI/EditableDescription";
import { useCreativeAnnotations } from "../../../hooks/useCreativeAnnotations";
import { UIStore } from "@stores/UIStore";
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { useEffect, useRef } from "react";
import { ProgressSlider } from "../ProgressSlider";
import { useAdScrollState } from "@controllers/ScrollController/useAdScrollState";
import { getScrollQueueFromScrollData } from "@controllers/ScrollController/getScrollQueue";
import { BiPause, BiPlay } from "react-icons/bi";
import useScrollData from "../../../hooks/useScrollData";
import { DEFAULT_DESC } from "../CONSTS";
import { HowDoWeCalculate } from "@composites/HowDoWeMeasure";

export const SimpleScrub = ({
  rechartsData,
  mainFrameRef,
  buildId,
  iframeState,
}) => {
  const {
    scrollSquiggle,
    scrollSquiggleBenchmark,

    scrollSquiggleLength,
    scrollSquiggleWithBenchmarkLength,
  } = rechartsData;
  const userInfo = UIStore((state) => state.userInfo);
  const playTimer = useRef(null);
  const { scrollStatus, scrollActions, currentProgress, currentY } =
    useAdScrollState(mainFrameRef);

  const { liveScrollSquiggle = [] } = useScrollData(currentY);

  const {
    annotations,
    setAnnotation,
    loading: annotationsLoading,
  } = useCreativeAnnotations({ buildId, format: "velocity" });

  const runSetup = () => {
    // sets up listener in the useAdScrollState hook
    // to pass through y position
    scrollActions.loadIFrame();

    const scrollConfig = {
      // initalY: 3000, //inital scroll position.
      durationScale: 2,
      scrollLengthScale: 0.25,
      // delayDurationScale: 2,
      minDuration: 0,
      maxDuration: 4000,
    };

    const scrollQueue = getScrollQueueFromScrollData(
      scrollSquiggle,
      scrollConfig
    );

    scrollActions.loadQueue(scrollQueue);

    playTimer.current = setTimeout(() => {
      scrollActions.play();
    }, 1500);
  };

  useEffect(() => {
    if (iframeState.iframeLoaded) runSetup();
    return () => {
      clearTimeout(playTimer.current);
    };
  }, [iframeState.iframeLoaded]);

  useEffect(() => {
    if (!iframeState.isHoveringIframe) return;
    clearTimeout(playTimer.current);
    scrollActions.pause();
  }, [iframeState.isHoveringIframe]);

  // if playing timeline, don't show live scroll squiggle
  // else show the live scroll squiggle IF there is one
  const lineValue = scrollStatus.playing
    ? scrollSquiggle
    : liveScrollSquiggle || scrollSquiggle;

  return (
    <>
      <HStack w="100%" alignItems="stretch" pt={"3%"} spacing={6} maxW="750px">
        <VStack w="100%" h="100%" gap="5" alignItems="flex-start">
          <Box
            pos="relative"
            w="100%"
            h="100%"
            border="1px solid"
            borderColor="gray.200"
            borderRadius="md"
          >
            <Box w="100%" h="100%" pos="relative">
              <ResponsiveContainer aspect={5 / 3} maxHeight={300}>
                <LineChart data={scrollSquiggle}>
                  <CartesianGrid strokeDasharray="1 3" />
                  <XAxis
                    dataKey="x"
                    hide={true}
                    type="number"
                    interval={"preserveEnd"}
                    domain={["dataMin", "dataMax"]}
                  />
                  <YAxis
                    dataKey="y"
                    hide={true}
                    domain={[
                      (dataMin) => dataMin * 2,
                      (dataMax) => dataMax * 2,
                    ]}
                  />
                  <Line
                    strokeWidth={1}
                    dot={false}
                    type="monotone"
                    data={scrollSquiggleBenchmark}
                    dataKey="y"
                    stroke="#C7C7C7"
                  />
                  <Line
                    strokeWidth={1}
                    dot={false}
                    type="monotone"
                    stroke="#00BA77"
                    data={lineValue}
                    dataKey="y"
                  />

                  <ReferenceLine
                    x={scrollSquiggleLength}
                    stroke="#00BA77"
                    animationDuration={4}
                    label={{
                      value: "This creative",
                      transform: "translate(0, 5)",
                      fill: "#00BA77",
                      ...refLineStyle,
                    }}
                  />
                  <ReferenceLine
                    x={scrollSquiggleWithBenchmarkLength}
                    stroke="#C7C7C7"
                    animationDuration={4}
                    label={{
                      value: "Benchmark*",
                      transform: "translate(0, 25)",
                      fill: "#C7C7C7",
                      ...refLineStyle,
                    }}
                  />
                </LineChart>
              </ResponsiveContainer>
              <ProgressSlider
                currentProgress={currentProgress}
                playFromPercent={scrollActions.playFromPercent}
              />
            </Box>
            <HStack
              w="100%"
              pos="relative"
              h="32px"
              borderRadius=" 0 0 6px 6px"
              overflow="hidden"
            >
              <ProgressSlider
                currentProgress={currentProgress}
                playFromPercent={scrollActions.playFromPercent}
                sliderTrackStyle={{ bg: "gray.200", opacity: 0.6 }}
              />
              <Box pos="absolute" top={0} left={0} zIndex={1}>
                <PlayButton
                  scrollStatus={scrollStatus}
                  scrollActions={scrollActions}
                />
              </Box>
            </HStack>
          </Box>
          <VStack w="100%" pb={6} alignItems="flex-start">
            <EditableDescription
              showEditControls={userInfo?.loggedIn}
              textStyle={{
                fontSize: "md",
                pl: 1,
                pr: 18,
                color: annotations?.description ? "black" : "gray.500",
              }}
              inputStyle={{ minHeight: "130px" }}
              fieldType="textarea"
              onEdit={(value) => setAnnotation("description", value)}
              value={annotations?.description || DEFAULT_DESC}
              isLoading={annotationsLoading}
            />
            <HowDoWeCalculate.Velocity />
          </VStack>
        </VStack>
      </HStack>
    </>
  );
};

const PlayButton = ({ scrollStatus, scrollActions }) => {
  const showPlayButton = !scrollStatus.playing;
  const showRestartButton =
    scrollStatus.currentMovementIndex === scrollStatus.scrollQueue.length;

  if (showRestartButton) {
    return (
      <IconButton
        size="sm"
        borderRadius={0}
        backgroundColor="gray.200"
        icon={<BiPlay />}
        onClick={() => scrollActions.playFromPercent(0)}
      />
    );
  }

  if (showPlayButton) {
    return (
      <IconButton
        size="sm"
        borderRadius={0}
        backgroundColor="gray.200"
        icon={<BiPlay />}
        onClick={() => scrollActions.resume()}
      />
    );
  }

  return (
    <IconButton
      size="sm"
      borderRadius={0}
      backgroundColor="gray.100"
      icon={<BiPause />}
      onClick={() => scrollActions.pause()}
    />
  );
};

const refLineStyle = {
  fontFamily: "roboto mono, monospace",
  fontSize: "0.8rem",
  position: "insideTopRight",
};
