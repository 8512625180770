import styled from "@emotion/styled";
import React from "react";
import { MetricPanel } from "@components/Metrics/MetricPanel";
import { ABGraph } from "@controllers/ABGraph/ABGraph";
import { ABPreviewWrapper } from "@controllers/ABPreviewWrapper/ABPreviewWrapper";

// ----------------------------------------------------------------------------
// Layout containers used in ABViewer

export const ViewerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding-top: 50px;
`;

export const PanelContainer = styled.div<{
  flexDirection: "row" | "column";
}>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || "row"};
  margin: 0 auto;
  width: 100%;
  height: 100%;

  // align-items: center;
  max-width: var(--maxScreenWidth);
  max-height: calc(100vh - 110px);
`;

// --------------------------------
//
const MetricPanelContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: 5%;
`;

// --------------------------------
// Mobile formatted creative

const MobileSidePanel = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: var(--space-4);
  height: 100%;
  max-width: 25%;
  max-height: 45vw;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const MobileMiddlePanel = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
  border-right: 1px solid var(--colors-neutral-4);
  border-left: 1px solid var(--colors-neutral-4);
  overflow-y: scroll;
  padding: var(--space-4) var(--space-4) 10vh var(--space-4);
  ::-webkit-scrollbar {
    display: none;
  }
`;

// -----------------------------
// Layout for mobile creatives

export const MobileAdLayout = ({
  creatives,
  wheelHandler,
  metArray,
  previewerRefs,
  keyColors,
}) => {
  return (
    <PanelContainer flexDirection="row">
      <MobileSidePanel onWheel={wheelHandler}>
        <ABPreviewWrapper
          {...creatives[0]}
          i={0}
          ref={previewerRefs.current}
          color={keyColors[0]}
        />
      </MobileSidePanel>

      <MobileMiddlePanel onWheel={wheelHandler}>
        {/* <MetricPanelContainer> */}
        {/* <MetricPanel
          {...creatives[0]}
          {...metArray[0]}
          orientation='vertical'
          color={keyColors[0]}
          showKey={true}
          showTitle={true}
        />
        <MetricPanel
          {...creatives[1]}
          {...metArray[1]}
          orientation='vertical'
          align="right"
          color={keyColors[1]}
          showKey={true}
          showTitle={true}
        /> */}
        {/* </MetricPanelContainer>
        <Hr /> */}
        <ABGraph creatives={creatives} colors={keyColors} />
      </MobileMiddlePanel>

      <MobileSidePanel onWheel={wheelHandler}>
        <ABPreviewWrapper
          {...creatives[1]}
          i={1}
          ref={previewerRefs.current}
          color={keyColors[1]}
        />
      </MobileSidePanel>
    </PanelContainer>
  );
};
