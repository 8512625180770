import firebase from "@services/firestore";
import { isCreativeOrAdBuyID } from "../util/idTypeChecker.js";

export const fetchIframeSourceDoc = async (buildId) => {

  // if the buildId is a ad line id or creative id, we'll need to find the buildId to load
  // here we'll need to query for a scrub session with the ad/creative id and access the parent collection for the buildId
  if (isCreativeOrAdBuyID(buildId)) {
    const dataRef = firebase
      .firestore()
      .collectionGroup("scrubSessions")
      .where("creativeID", "==", buildId)
      .limit(1);
    const querySnapshot = await dataRef.get();
    if (querySnapshot.docs.length > 0) {
      buildId = querySnapshot.docs[0].ref.parent.parent.id;
    } else {
      throw "No data.";
    }
  }
  // note: this above logic is repeated in /hooks/useStudioBuildID.js - it would be good to consolidate this logic

  const creativeURL = `https://studio.playgroundxyz.com/${buildId}/quiet?pos=middle-ad&size=300x250&dpframe=0`;
  return fetch(creativeURL)
    .then((res) => res.text())
    .then(
      (result) => result,
      (error) => {
        console.error("Cannot fetch the creative:", error);
      }
    );
};
