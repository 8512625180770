import { UIStore } from '@stores/UIStore';
import { isVideoPlaying } from '@utils/creativeUtil';
import { useEffect, useMemo, useState } from 'react';
import { Chart } from './Chart';
import {
  Flex,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  IconButton,
  Switch,
  Text,
  Box,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { BiPlay, BiPause } from 'react-icons/bi';
import { VideoFrames } from './VideoFrames';

const SingleVideoChart = ({
  showBaseline = false,
  iframeVideo = null,
  videoSequences,
  videoDropOffBaseline = Array.from({ length: 101 }, () => 0),
  byDurationVideoDropOffBaselines = null,
}) => {
  const [showLegacyVideoChart, setShowLegacyVideoChart] = useState(false);
  const userInfo = UIStore((state) => state.userInfo);
  const iframeVideoProgress = UIStore((state) => state.iframeVideoProgress);
  const setIframeVideoProgress = UIStore(
    (state) => state.setIframeVideoProgress
  );
  const [videoIsPlaying, setVideoIsPlaying] = useState(true);
  const videoRangeChange = (event) => {
    setIframeVideoProgress(event.target.value);
    let newTime = (iframeVideo.duration / 100) * event.target.value;
    if (newTime) {
      iframeVideo.currentTime = newTime;
    }
  };
  const [useLegacyBaseline, setUseLegacyBaseline] = useState(false);
  useEffect(() => {
    const isPlaying = isVideoPlaying(iframeVideo);
    setVideoIsPlaying(isPlaying);
  }, [iframeVideo, isVideoPlaying(iframeVideo)]);

  function percentChange(oldValue, newValue) {
    let change = newValue - oldValue;
    let percentChange = (change / oldValue) * 100;
    return percentChange;
  }
  const videoUrl = iframeVideo
    ? iframeVideo.getElementsByTagName('source')[0].src
    : null;
  const videoDuration = iframeVideo ? Math.floor(iframeVideo.duration) : null;
  const formatForChart = (data, key, baseline) => {
    if (!data[key].length) return;
    return data[key].map((value, index) => {
      return {
        inView: value,
        timeline: index,
        baseline: baseline[index],
        difference: percentChange(baseline[index], value), // value - baseline[index] 👈🏻 value to use for straight difference
      };
    });
  };

  function findClosest(target, arr, key) {
    return arr.reduce((prev, curr) =>
      Math.abs(curr[key] - target) < Math.abs(prev[key] - target) ? curr : prev
    );
  }
  let chartData = useMemo(() => {
    if (
      !byDurationVideoDropOffBaselines ||
      byDurationVideoDropOffBaselines.length === 0
    )
      return;
    // get closest baseline
    const videoBaseline = findClosest(
      videoDuration,
      byDurationVideoDropOffBaselines,
      'duration'
    );
    return formatForChart(
      videoSequences,
      'videoDropOffSequence',
      useLegacyBaseline ? videoDropOffBaseline : videoBaseline.videoDropOff // videoDropOffBaseline 👈🏻 old baseline array
    );
  }, [
    videoSequences['videoDropOffSequence'],
    byDurationVideoDropOffBaselines,
    useLegacyBaseline,
  ]);

  // -----------------------------------------------------
  // should be in presentation mode?
  const queryParams = new URLSearchParams(location.search);
  const isPresentationMode = queryParams.get('layoutMode') === 'presentation';
  // this is the URL param that is passed thorugh when gif-generator
  // is viewing the page to create a gif for the slide deck
  // console.log('SingleVideoChart.js: isPresentationMode:', isPresentationMode);
  return (
    <>
      <Chart
        id="SingleVideoChart"
        data={chartData}
        progress={iframeVideoProgress}
        showBaseline={showBaseline}
        videoDuration={videoDuration}
        videoChange={(val) => videoRangeChange({ target: { value: val } })}
        showLegacyVideoChart={showLegacyVideoChart}
      />
      <Flex
        marginLeft="10px"
        marginRight={'6px'}
        direction={'column'}
        marginTop="-66px"
      >
        <Flex direction="row">
          <IconButton
            opacity={isPresentationMode ? '0' : '1'}
            size="sm"
            isRound={true}
            variant={'ghost'}
            icon={
              videoIsPlaying ? <BiPause size="20px" /> : <BiPlay size="20px" />
            }
            onClick={() => {
              videoIsPlaying ? iframeVideo.pause() : iframeVideo.play();
              setVideoIsPlaying(!videoIsPlaying);
            }}
          />

          <Flex direction={'column'} marginLeft={'5px'} width="100%">
            <Slider
              value={iframeVideoProgress}
              onChange={(val) => videoRangeChange({ target: { value: val } })}
              colorScheme="blackAlpha"
              mt="9px"
              mb="0"
            >
              <SliderTrack>
                <SliderFilledTrack id="imagesVideo" />
              </SliderTrack>
              <SliderThumb />
            </Slider>
            <Box width="100%" position={'relative'} mt="-5px">
              <VideoFrames videoUrl={videoUrl} videoDuration={videoDuration} />
            </Box>
          </Flex>
        </Flex>

        {userInfo.loggedIn && (
          <Flex
            direction="column"
            alignItems="start"
            ml="34px"
            display={isPresentationMode ? 'none' : 'flex'}
          >
            <LeftLabelSwitch id='useLegacyBaseline' label='use legacy video baseline' isChecked={useLegacyBaseline} onChange={setUseLegacyBaseline} />
            <LeftLabelSwitch id='showLegacyVideoChart' label='show legacy video chart' isChecked={showLegacyVideoChart} onChange={setShowLegacyVideoChart} />
          </Flex>
        )}
      </Flex>
    </>
  );
};


const LeftLabelSwitch = ({ id, label, isChecked, onChange }) => {
  return (
    <FormControl display='flex' alignItems="center" cursor='pointer'>
      <FormLabel fontWeight={400} htmlFor={id} fontSize={'sm'} mr={3} mb='0'>
        {label}
      </FormLabel>
      <Switch
        id={id}
        size={'sm'}
        colorScheme="blackAlpha"
        isChecked={isChecked}
        onChange={(e) => onChange && onChange(e.target.checked)}
      />
    </FormControl>
  );
}



export default SingleVideoChart;
