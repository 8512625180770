import { userMetadata } from "@data/userMetadata.js";
import firebase from "@services/firestore";
import { GUIStore } from "@stores/GUIStore";
import { UIStore } from "@stores/UIStore";
import React, { useEffect, useState } from "react";
import { logAnalytics } from "@utils/analyticsUtil";
import {
  GUIFootnote,
  GUIRow,
  GUISelect,
  GUIStepper,
  GUISwitch,
  GUIWrapper,
} from "./";
import { CreativeStore } from "../../store/CreativeStore";

const GUI = ({ domainsList = [], dwellTimes, defaultBaseline, ...rest }) => {
  const showGUI = GUIStore((state) => state.showGUI);
  const GUIToggle = GUIStore((state) => state.GUIToggle);
  const minSequenceLength = GUIStore((state) => state.minSequenceLength);
  const setMinSequenceLength = GUIStore((state) => state.setMinSequenceLength);
  const showDwellTime = GUIStore((state) => state.showDwellTime);
  const setShowDwellTime = GUIStore((state) => state.setShowDwellTime);
  const startProgress = GUIStore((state) => state.startProgress);
  const endProgress = GUIStore((state) => state.endProgress);
  const setStartProgress = GUIStore((state) => state.setStartProgress);
  const setEndProgress = GUIStore((state) => state.setEndProgress);
  const setDomainWhiteList = GUIStore((state) => state.setDomainWhiteList);
  const setBaselineOverride = GUIStore((state) => state.setBaselineOverride);
  const userInfo = UIStore((state) => state.userInfo);
  const updateChart = CreativeStore((state) => state.updateChart);

  // userMetadata
  const [canViewDwellTime, setCanViewDwellTime] = useState(false);

  const dwellTimeToggleHandler = (e) => {
    firebase.analytics().logEvent("gui_toggle_include_duration" + e);
    logAnalytics.guiAdjust("dwell_time", e);
    setShowDwellTime(e);
  };

  const scrubNumberHandler = ({ step }) => {
    firebase
      .analytics()
      .logEvent(
        "gui_min_scrub_number_setting:" + Math.sign(step) ? "plus" : "minus"
      );
    logAnalytics.guiAdjust("scrub_number", Math.sign(step) ? "plus" : "minus");
    setMinSequenceLength(minSequenceLength + step);
  };

  const startProgressHandler = ({ step }) => {
    firebase
      .analytics()
      .logEvent("gui_progress_start:" + Math.sign(step) ? "plus" : "minus");
    logAnalytics.guiAdjust(
      "start_progress",
      Math.sign(step) ? "plus" : "minus"
    );
    setStartProgress(startProgress + step);
  };

  const endProgressHandler = ({ step }) => {
    firebase
      .analytics()
      .logEvent("gui_progress_end:" + Math.sign(step) ? "plus" : "minus");
    logAnalytics.guiAdjust("end_progress", Math.sign(step) ? "plus" : "minus");
    setEndProgress(endProgress + step);
  };

  useEffect(() => {
    const matchingUser = userMetadata.filter((userMeta) => {
      return (
        userInfo.email.toLowerCase().includes(userMeta.email.toLowerCase()) ||
        userMeta.email.toLowerCase().includes(userInfo.email.toLowerCase())
      );
    });
    if (matchingUser.length) {
      setCanViewDwellTime(
        !!matchingUser[0].team &&
          (matchingUser[0].team === "CPT" ||
            matchingUser[0].team === "design" ||
            matchingUser[0].team === "staff")
      );
    }
  }, [userInfo]);

  useEffect(() => {
    if (showGUI) {
      logAnalytics.guiPanel("open");
    }
  }, [showGUI]);

  const handleDomainWhitelistChange = (value) => {
    const domain =
      value.target.value === domainOptions[0].label ? null : value.target.value;
    if (domain === null) return;
    updateChart(domainsList.find((d) => d.domain === domain));
    // wanted to get sessions in here too but couldn't figure out how
    logAnalytics.guiDomainWhitelistChange(domain, window.location.href, false);
  };

  const handleBaselineChange = (value) => {
    setBaselineOverride(value.target.value);
    logAnalytics.guiAdjust("baseline_filter", value.target.value);
  };

  const domainOptions = [
    { key: null, label: "All Domains" },
    ...domainsList
      .sort((a, b) => b.sessionCount - a.sessionCount)
      .map(({ domain, sessionCount }) => ({
        label: `${domain} (sessions: ${sessionCount})`,
        key: domain,
      })),
  ];

  return (
    <GUIWrapper show={showGUI} onToggle={GUIToggle} {...rest}>
      {/**
       * TODO: Add back in when vertical baselines and domain things
       * have been thought about further
       */}
      {domainsList && (
        <GUIRow title={"Domain Filter"}>
          <GUISelect
            options={domainOptions}
            defaultValue={domainOptions[0].label}
            onChange={handleDomainWhitelistChange}
          />
        </GUIRow>
      )}
      {/* {defaultBaseline && (
        <GUIRow title={"Baseline Filter"}>
          <GUISelect
            options={[
              { key: "vertical", label: "Vertical" },
              { key: "format", label: "Format" },
            ]}
            defaultValue={defaultBaseline}
            onChange={handleBaselineChange}
          />
        </GUIRow>
      )} */}
      {/* <GUIRow title={"Min. # of Scrubs"}>
        <GUIStepper
          lowerbound={0}
          upperbound={30}
          currentValue={minSequenceLength}
          onChange={scrubNumberHandler}
        />
      </GUIRow> */}
      <GUIRow title={"Ad Progress Start"}>
        <GUIStepper
          lowerbound={0}
          upperbound={100}
          currentValue={startProgress}
          onChange={startProgressHandler}
          step={5}
        />
      </GUIRow>
      <GUIRow title={"Ad Progress End"}>
        <GUIStepper
          lowerbound={0}
          upperbound={100}
          currentValue={endProgress}
          onChange={endProgressHandler}
          step={5}
        />
      </GUIRow>
      <GUIRow title={"Show Dwell Time"}>
        {canViewDwellTime && dwellTimes?.length ? (
          <GUISwitch onChange={dwellTimeToggleHandler} />
        ) : (
          <> No dwell times have loaded yet ... </>
        )}
      </GUIRow>

      <GUIFootnote>
        *This is an experimental feature. None of the settings will be stored.
      </GUIFootnote>
    </GUIWrapper>
  );
};

export default GUI;
