import semverSort from "semver-sort";
import create from "zustand";
const UIStore = create((set, get) => ({
  // this is the default state of the application
  userInfo: null,
  upScrollHeat: 0,
  scrollHeat: 0,
  showScrollHeat: false,
  showUpScrollHeat: false,
  viewerScale: 1,
  showModal: {
    open: false,
    text: "",
  },
  availableScriptVersions: [],
  selectedScriptVersions: [],
  availableVerticals: [],
  selectedVerticals: [],
  setShowModal: (state, text) => {
    set({ showModal: { open: state, text: text } });
    console.log(text);
  },
  setUserInfo: (useInfo) => {
    set({ userInfo: useInfo });
  },
  setLogOut: () => {
    set({
      userInfo: {
        displayName: "",
        email: "",
        photoURL: "",
        loggedIn: false,
        id: "",
        missingDemoCreative: false,
      },
    });
  },
  videoFrames: {},
  setVideoFrames: (frames) => {
    set({ videoFrames: { ...get().videoFrames, ...frames } });
  },
  iframeVideoProgress: 0,
  setIframeVideoProgress: (progress) => {
    set({ iframeVideoProgress: progress });
  },
  addAvailableScriptVersion: (version) => {
    let availableScriptVersionsClone = [...get().availableScriptVersions];
    availableScriptVersionsClone.push(version);
    availableScriptVersionsClone = semverSort.desc(
      availableScriptVersionsClone
    );
    set({ availableScriptVersions: availableScriptVersionsClone });
  },
  addAvailableVerticals: (vertical) => {
    let availableVerticalsClone = [...get().availableVerticals];
    availableVerticalsClone.push(vertical);
    availableVerticalsClone.sort();
    set({ availableVerticals: availableVerticalsClone });
  },
  setMissingDemoCreative: (value) => {
    set((state) => ({
      userInfo: { ...state.userInfo, missingDemoCreative: value },
    }));
  },
}));

export { UIStore };
